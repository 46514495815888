<template>
  <div class="">
    <div class="container pb-4 pt-4">
      <div class="" style="margin-top: 24px ">
        <h2 class="pb-2">{{ $t("swidry1") }}</h2>
        <p class="text-justify ">
            {{ $t("swidry1-text") }}
        </p>
        <div class="button1">{{ $t("seria-s") }}</div>
      </div>
    </div>


  
    <div class="container">
      <div class="">
        <div class="">
          <div  class="pb-4">
            <figure class="pointer">
              <img src="/assets/images/gallery18.jpg" alt="TECHNIGOR open-bearing roller cone bits" class="img-fluid"
                @click="openModalImg1 = !openModalImg1" />
            </figure>
          </div>
        </div>
        <div class="pt-4 text-justify">
          <h6>{{ $t("swidry1-textA") }}</h6>
          <p class="text-justify">{{ $t("swidry1-textB") }}</p>
          <hr />
          <h6>{{ $t("swidry1-text2") }}</h6>
          <div class="">
            <div class="">
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{
                    $t("swidry1-text3")
                }}</span>
              </label>
            </div>
            <div> 
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{ $t("swidry1-text4") }}
                </span>
              </label>
            </div>
            <div class="">
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{ $t("swidry1-text5") }}
                </span>
              </label>
            </div>
            <div>  
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{
                    $t("swidry1-text6")
                }}</span>
              </label>
            </div>
          </div>
          <hr />
          <p class="text-justify">
            {{ $t("swidry1-text7") }}
          </p>
          <div class="row">

        <div class="col-4">
          <div class="">
            <figure class="pointer">
              <img src="/assets/images/gallery12.jpg" alt="TECHNIGOR roller cone bit 4-3/4 in IADC 531Y"
                class="img-fluid" @click="openModalImg2 = !openModalImg2" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg2" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/gallery12.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>

        <div class="col-4">
          <div class="">
            <figure class="pointer">
              <img src="/assets/images/nieuszczelniony-productpage.jpg"
                alt="TECHNIGOR roller cone bit 8-1/2 in IADC 211" class="img-fluid"
                @click="openModalImg3 = !openModalImg3" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg3" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/nieuszczelniony-productpage.jpg">
              </v-img>
            </v-card>
          </v-dialog>
        </div>

        <div class="col-4">
          <div class="">
            <figure class="pointer">
              <img src="/assets/images/nieuszczelniony-productpage2.jpg"
                alt="TECHNIGOR roller cone bit 2-5/8 in IADC 633Y" class="img-fluid"
                @click="openModalImg4 = !openModalImg4" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg4" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/nieuszczelniony-productpage2.jpg">
              </v-img>
            </v-card>
          </v-dialog>
        </div>

         </div>
        </div>
      </div>
    </div>

  </div>
      
  
  
</template>

<script>
export default {
  name: "ProductsTab1",
  data() {
    return {
      openModalImg1: false,
      openModalImg2: false,
      openModalImg3: false,
      openModalImg4: false
    };
  }
};
</script>

<style>
.button1 {
  border: 2px solid #484848;
  color: #484848;
  background-color: #ffffff;
  padding: 2px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
}
</style>

<template>
  <div>
    <div class="gray-dark booking-details_wrap">
      <div class="">
        <div class="titile-block title-block_subpage">
          <h2>{{ $t("drilling_practices1") }}</h2>
        </div>
      </div>
    </div>


    <!--//END HOW IT WORKS -->

    <!-- item list  -->
    <div class="container">
      <div class="row main-block p-4 col-12">
        <div class="container">
          <div class="titile-block"></div>
          <div class="blog-text col-16 row article-first">
            <h2 class="mb-2 ">{{ $t("drilling_practices5") }}</h2>
            <ol class="text-justify mb-2 pb-4  pt-4">
              <li>{{ $t("drilling_practices5a") }}</li>
              <li>{{ $t("drilling_practices5b") }}</li>
              <li>{{ $t("drilling_practices5c") }}</li>
              <br />
              <h6 class="m-2 pb-4">
                <strong>{{ $t("drilling_practices5d") }}</strong>
                {{ $t("drilling_practices5e") }}
              </h6>
              <br />
              <li>{{ $t("drilling_practices5f") }}</li>
            </ol>
          </div>
          <div class="table-responsive-1 col-12 pt-8 mt-4">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="center" colspan="2">
                    {{ $t("drilling_practices2") }}
                  </th>
                  <th class="pt-0">
                    {{ $t("drilling_practices3") }}
                  </th>
                  <th class="pt-0" colspan="2">
                    {{ $t("drilling_practices4") }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th class="pt-0">
                    <span class="badge badge-success">{{ $t("inch") }}</span>
                  </th>
                  <th class="pt-0">
                    <span class="badge badge-success">[mm]</span>
                  </th>
                  <th class="pt-0"></th>
                  <th class="pt-0">
                    <span class="badge badge-success">{{ $t("ft_lbf") }}</span>
                  </th>
                  <th class="pt-0">
                    <span class="badge badge-success">[N x m]</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2–9/32 ÷ 2–3/8 {{ $t("incl.") }}</td>
                  <td>57,9 ÷ 60,3 {{ $t("incl.") }}</td>
                  <td>A–Rod</td>
                  <td>350 ÷ 410</td>
                  <td>470 ÷ 560</td>
                </tr>
                <tr>
                  <td>2–1/2 ÷ 3–1/2 {{ $t("incl.") }}</td>
                  <td>63,5 ÷ 88,9 {{ $t("incl.") }}</td>
                  <td>N–Rod</td>
                  <td>600 ÷ 700</td>
                  <td>800 ÷ 950</td>
                </tr>
                <tr>
                  <td>3–1/2 ÷ 4–1/2 {{ $t("incl.") }}</td>
                  <td>88,9 ÷ 114,3 {{ $t("incl.") }}</td>
                  <td>2–3/8 Reg</td>
                  <td>3 000 ÷ 3 500</td>
                  <td>4 000 ÷ 4 800</td>
                </tr>
                <tr>
                  <td>4–5/8 ÷ 5 {{ $t("incl.") }}</td>
                  <td>117,5 ÷ 127,0 {{ $t("incl.") }}</td>
                  <td>2–7/8 Reg</td>
                  <td>4 500 ÷ 5 500</td>
                  <td>6 000 ÷ 7 000</td>
                </tr>
                <tr>
                  <td>5–1/8 ÷ 7–3/8 {{ $t("incl.") }}</td>
                  <td>130,2 ÷ 187,3 {{ $t("incl.") }}</td>
                  <td>3–1/2 Reg</td>
                  <td>7 000 ÷ 9 000</td>
                  <td>9 500 ÷ 12 000</td>
                </tr>
                <tr>
                  <td>7–1/2 ÷ 9–1/2 {{ $t("incl.") }}</td>
                  <td>190,5 ÷ 241,3 {{ $t("incl.") }}</td>
                  <td>4–1/2 Reg</td>
                  <td>12 000 ÷ 16 000</td>
                  <td>16 000 ÷ 22 000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="container">
      <div class="row main-block p-4 col-12">
        <div class="">
          <div class=""></div>
          <div class="blog-text col-16 row article-first pb-2">
            <h2 class="mb-2 pb-4">{{ $t("drilling_practices6") }}</h2>
            <ol class="text-justify">
              <li>{{ $t("drilling_practices6a") }}</li>
              <li>{{ $t("drilling_practices6b") }}</li>
              <ol type="a" >
                <li>{{ $t("drilling_practices6c") }}</li>
                {{
                  $t("drilling_practices6d")
                }}
                <li>{{ $t("drilling_practices6e") }}</li>
                <li>{{ $t("drilling_practices6f") }}</li>
                <li>{{ $t("drilling_practices6g") }}</li>
                <li>{{ $t("drilling_practices6h") }}</li>
              </ol>
              <li>{{ $t("drilling_practices6i") }}</li>
            </ol>
          </div>
          </div>
        </div>
      </div>
      <div class="">
      <div class="row main-block p-4 col-12">
        <div class="container">
          <div class=""></div>
          <div class="blog-text col-16 row article-first pb-2">
            <h2 class="mb-2 pb-4 ">{{ $t("drilling_practices7") }}</h2>
            <ol type="1" class="text-justify pb-4">
              <li>{{ $t("drilling_practices7a") }}</li>
              <li>{{ $t("drilling_practices7b") }}</li>
              <li>{{ $t("drilling_practices7c") }}</li>
              <li>{{ $t("drilling_practices7d") }}</li>
            </ol>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DrillingPractices"
};
</script>

<template>
  <div class="">
    <div class="container pb-4 pt-4">
      <div class="" style="margin-top: 24px ">
        <h2>{{ $t("swidry4") }}</h2>
        <p class="text-justify">
          {{ $t("swidry4-text") }}
        </p>
      </div>
    </div>



    <div class="container">
      <div class="">
          <div class="">
              <div  class="pb-4">
                <figure class="pointer">
                  <img src="/assets/images/pompy-ciepla1.jpg" alt="TECHNIGOR roller cone bit 5.625 in IADC 211"
                    class="img-fluid" @click="openModalImg1 = !openModalImg1" />
                </figure>
                </div>
              </div>
            </div>
          </div>
            <div class="pt-4 container">
             <div class="">
              <h6>{{ $t("swidry4-text2") }}</h6>
              <p class="text-justify">{{ $t("swidry4-text3") }}</p>
              <p class="text-justify">{{ $t("swidry4-text3-2") }}</p>
              <hr />
              <h6>{{ $t("swidry4-text4") }}</h6>
              <div class="">
                <div class="">
                  <label class="custom-checkbox">
                    <span class="ti-check-box"></span>
                    <span class="custom-control-description">{{
                        $t("swidry4-text5")
                    }}</span>
                  </label>
                </div>
                <div> 
                  <label class="custom-checkbox">
                    <span class="ti-check-box"></span>
                    <span class="custom-control-description">{{ $t("swidry4-text6") }}
                    </span>
                  </label>
                </div>
                <div>
                  <label class="custom-checkbox">
                    <span class="ti-check-box"></span>
                    <span class="custom-control-description">{{ $t("swidry4-text7") }}
                    </span>
                  </label>
                </div>
                <div>  
                  <label class="custom-checkbox">
                    <span class="ti-check-box"></span>
                    <span class="custom-control-description">{{
                        $t("swidry4-text8")
                    }}</span>
                  </label>
                </div>
                <div>   
                  <label class="custom-checkbox">
                    <span class="ti-check-box"></span>
                    <span class="custom-control-description">{{
                        $t("swidry4-text9")
                    }}</span>
                  </label>
                </div>
              </div>
              <hr />
              <p class="text-justify">
                {{ $t("swidry4-text10") }}
              </p>

              <div class="row">

            <div class="col-4">



                <div class="">
                  <figure class="pointer">
                    <img src="/assets/images/pompy-ciepla2.jpg" alt="TECHNIGOR roller cone bit 4.75 in IADC 211"
                      class="img-fluid" @click="openModalImg2 = !openModalImg2" />
                  </figure>
                </div>
                <v-dialog v-model="openModalImg2" class="modal" transition="dialog-bottom-transition" width="62%">
                  <v-card>
                    <v-img src="/assets/images/pompy-ciepla2.jpg"> </v-img>
                  </v-card>
                </v-dialog>
              </div>

              <div class="col-4">
                <div class="">
                  <figure class="pointer">
                    <img src="/assets/images/pompy-ciepla3.jpg" alt="TECHNIGOR roller cone bit 5.5 in IADC 217"
                      class="img-fluid" @click="openModalImg3 = !openModalImg3" />
                  </figure>
                </div>
                <v-dialog v-model="openModalImg3" class="modal" transition="dialog-bottom-transition" width="62%">
                  <v-card>
                    <v-img src="/assets/images/pompy-ciepla3.jpg"> </v-img>
                  </v-card>
                </v-dialog>
              </div>

              <div class="col-4">
                <div class="">
                  <figure class="pointer">
                    <img src="/assets/images/pompy-ciepla4.jpg" alt="TECHNIGOR roller cone bit 5.625 in IADC 531Y"
                      class="img-fluid" @click="openModalImg4 = !openModalImg4" />
                  </figure>
                </div>
                <v-dialog v-model="openModalImg4" class="modal" transition="dialog-bottom-transition" width="62%">
                  <v-card>
                    <v-img src="/assets/images/pompy-ciepla4.jpg"> </v-img>
                  </v-card>
                </v-dialog>
              </div>
           </div>
        </div>
     </div>
  </div>
</template>

<script>
export default {
  name: "ProductsTab4",
  data() {
    return {
      openModalImg1: false,
      openModalImg2: false,
      openModalImg3: false,
      openModalImg4: false
    };
  }
};
</script>

<template>
  <div class="">
    <div class="container pb-4 pt-4">
      <div class="" style="margin-top: 24px ">
        <h2 class="pb-2">{{ $t("swidry3") }}</h2>
        <p class="text-justify">
          {{ $t("swidry3-text") }}
        </p>
        <div class="button1">{{ $t("seria-sr") }}</div>
      </div>
    </div>
    <div class="container">
      <div class="">
        <div class="">
          <div  class="pb-4">
            <figure class="pointer">
              <img src="/assets/images/lewy-obieg1.jpg"
                alt="TECHNIGOR roller cone bit 5-1/8 in IADC 623 reverse circulation 01" class="img-fluid"
                @click="openModalImg1 = !openModalImg1" />
            </figure>
          </div>

        </div>
      </div>
      <div class="pt-4 text-justify">
        <div class="">
          <h6>
            {{ $t("swidry2-textA") }}
          </h6>
          <p class="text-justify">
            {{ $t("swidry3-text1") }}
          </p>
          <hr />
          <h6>{{ $t("swidry3-text2") }}</h6>
          <div class="">
            <div class="">
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{
                    $t("swidry3-text3")
                }}</span>
              </label>
            </div>
            <div>  
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{ $t("swidry3-text4") }}
                </span>
              </label>
            </div>
            <div>  
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{
                    $t("swidry3-text7")
                }}</span>
              </label>
            </div>
            <div class="">
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{ $t("swidry3-text5") }}
                </span>
              </label>
            </div>
            <div>  
              <label class="custom-checkbox">
                <span class="ti-check-box"></span>
                <span class="custom-control-description">{{
                    $t("swidry3-text6")
                }}</span>
              </label>
            </div>
          </div>
          <hr />
          <p class="text-justify">
            {{ $t("swidry3-text8") }}
          </p>
        </div>
    </div>
      <div class="row">
        <div class="col-4">
          <div class="grid">
            <figure class="pointer">
              <img src="/assets/images/lewy-obieg2.jpg"
                alt="TECHNIGOR roller cone bit 5-1/8 in IADC 623 reverse circulation 02" class="img-fluid"
                @click="openModalImg2 = !openModalImg2" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg2" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/lewy-obieg2.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="col-4">
          <div class="grid">
            <figure class="pointer">
              <img src="/assets/images/lewy-obieg3.jpg"
                alt="TECHNIGOR roller cone bit 5-1/2 in IADC 211 reverse circulation 01" class="img-fluid"
                @click="openModalImg3 = !openModalImg3" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg3" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/lewy-obieg3.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="col-4">
          <div class="grid">
            <figure class="pointer">
              <img src="/assets/images/lewy-obieg4.jpg"
                alt="TECHNIGOR roller cone bit 5-1/2 in IADC 211 reverse circulation 02" class="img-fluid"
                @click="openModalImg4 = !openModalImg4" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg4" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/lewy-obieg4.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsTab3",
  data() {
    return {
      openModalImg1: false,
      openModalImg2: false,
      openModalImg3: false,
      openModalImg4: false
    };
  }
};
</script>

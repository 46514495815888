<template>
  <div>
    <!--============================= DETAILED ITEM  =============================-->
    <section class="subpage-bg">
      <div class="row">
        <div class="card col-md-2 m-0 pt-8 ">
          <div class="card-header">
            <strong>{{ $t("tool_category") }}</strong>
          </div>
          <ul class="list-group list-group-flush">
            <router-link to="/products-category/1">
              <li class="list-group-item" :class="{ selected: id == 1 }">
                {{ $t("swidry1-1") }}<br />
                {{ $t("swidry1-1-1") }}
              </li></router-link
            >
            <!--<router-link to="/products-category/2"
              ><li class="list-group-item" :class="{ selected: id == 2 }">
                {{ $t("swidry2") }}<br />
                {{ $t("swidry2-2") }}
              </li></router-link
            >-->
            <router-link to="/products-category/3"
              ><li class="list-group-item" :class="{ selected: id == 3 }">
                {{ $t("swidry3") }}
              </li></router-link
            >
            <router-link to="/products-category/4"
              ><li class="list-group-item" :class="{ selected: id == 4 }">
                {{ $t("swidry4") }}
              </li></router-link
            >
            <router-link to="/products-category/5"
              ><li class="list-group-item" :class="{ selected: id == 5 }">
                {{ $t("swidry5") }}<br />
                {{ $t("swidry6") }}
              </li></router-link
            >
          </ul>
        </div>
        <div class="card col-md-10 mb-0 pt-0 pb-0 shadow">
          <products-tab-1 v-if="id == 1" />
          <products-tab-2 v-if="id == 2" />
          <products-tab-3 v-if="id == 3" />
          <products-tab-4 v-if="id == 4" />
          <products-tab-5 v-if="id == 5" />
        </div>
      </div>
    </section>
    <!--//END BLOG -->
    <!--============================= Product category =============================-->

    <section class="gray-dark booking-details_wrap">
      <div class="container">
        <ProductsTable></ProductsTable>
      </div>
    </section>
    <!-- Product category no1   -->
  </div>
</template>

<script>
import ProductsTable from "./ProductsTable";
import ProductsTab1 from "./ProductsTabs/ProductsTab1";
import ProductsTab2 from "./ProductsTabs/ProductsTab2";
import ProductsTab3 from "./ProductsTabs/ProductsTab3";
import ProductsTab4 from "./ProductsTabs/ProductsTab4";
import ProductsTab5 from "./ProductsTabs/ProductsTab5";

export default {
  name: "Products",
  components: {
    ProductsTable,
    ProductsTab1,
    ProductsTab2,
    ProductsTab3,
    ProductsTab4,
    ProductsTab5
  },
  data() {
    return {
      productTab: "tab1"
    };
  },
  computed: {
    id() {
      console.log(this.$route.params);

      return this.$route.params.id;
    }
  }
};
</script>

<style>
.list-group-item {
  color: #424242;
  text-transform: uppercase;
}

.list-group-item:hover {
  color: #6a8e4d;
  text-transform: uppercase;
  text-decoration: none !important;
  text-decoration-color: white;
}

.selected {
  color: white;
  background-color: #2d473f;
  /* selected item attributes here */
}

a:hover {
  text-decoration: none;
}
</style>

<template>
  <div>
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("about_us") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--// SUBPAGE HEADER BG -->
    <!--============================= BLOG DETAIL =============================-->




    <section class="container ">
      <div>
        <div class="blog-text col-12 pt-16">
          <div>
            <div>
              <figure class="img-holder pb-16 content-justify">
                <img
                  src="/assets/images/headquarters.jpg"
                  class="img-fluid"
                  alt="headquarters"
                />
              </figure>
              <div class="col-12">
                <div class="blog-text row article-first pb-10">
                  <br /><br />
                  <h2>{{ $t("technigor") }}</h2>
                  <div>
                    <p class=" text-justify ">{{ $t("about_us_text") }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--//END BLOG DETAIL -->
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

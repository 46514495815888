<template>
  <div>
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("contact_us") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--// SUBPAGE HEADER BG -->
    <!--============================= CONTACT =============================-->
    <section class="container center">
      <div>
        <div class=" main-block">
          <div>
            <div>
              <figure class="img-holder">
                <img
                  src="/assets/images/headquarters-night.jpg"
                  class="img-fluid"
                  alt="headquarters"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="">
      <div class="container-fluid">
        <div class="row justify-content-center mb-16">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <div class="address-box">
                  <span class="ti-location-pin"></span>
                  <h2>{{ $t("contact_us1") }}</h2>
                  <p>
                    {{ $t("technigor") }}<br />
                    {{ $t("street2") }}<br />
                    38-300 Gorlice<br />
                    {{ $t("poland") }}
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="address-box">
                  <span class="ti-user"></span>
                  <h2>{{ $t("contact") }}</h2>
                  <p>
                    <i
                      class="fa fa-envelope-o"
                      aria-hidden="true"
                      align="center"
                    ></i>
                    sekretariat@technigor.pl <br />
                    <i
                      class="fa fa-phone"
                      aria-hidden="true"
                      align="center"
                    ></i>
                    +48 882 908 099 <br /><br /><br />
                  </p>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Contact"
};
</script>

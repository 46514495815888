<template>
  <div class="">
    <div class="container pb-4 pt-4">
      <div class="" style="margin-top: 24px ">
        <h2>{{ $t("swidry5-1") }}</h2>
      </div>
    </div>
    <div class="container">
      <div class="">
          <div class="pb-4">
            <figure class="pointer">
              <img src="/assets/images/zespolyrolkowe1.jpg" alt="TECHNIGOR leg sets 8-1/2 in IADC 633Y"
                class="img-fluid" @click="openModalImg1 = !openModalImg1" />
            </figure>
          </div>
      </div>

      <div class="">
      <div class="">
        <p class="text-justify">
          {{ $t("swidry5-text") }}
        </p>
        <hr />
        <h6>{{ $t("swidry5-text2") }}</h6>
        <div class="">
          <div class="">
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{
                  $t("swidry5-text3")
              }}</span>
            </label>
          </div>
          <div>  
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{ $t("swidry5-text4") }}
              </span>
            </label>
          </div>
          <div class="">
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{ $t("swidry5-text5") }}
              </span>
            </label>
          </div>
          <div>  
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{
                  $t("swidry5-text6")
              }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>





      <div class="row">
        <div class="col-4">
          <div class="grid">
            <figure class="pointer">
              <img src="/assets/images/zespolyrolkowe2.jpg" alt="TECHNIGOR leg sets 6-3/4 in IADC 211" class="img-fluid"
                @click="openModalImg2 = !openModalImg2" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg2" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/zespolyrolkowe2.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="col-4">
          <div class="grid">
            <figure class="pointer">
              <img src="/assets/images/zespolyrolkowe3.jpg" alt="TECHNIGOR leg sets 4-1/2 in IADC 223" class="img-fluid"
                @click="openModalImg3 = !openModalImg3" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg3" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/zespolyrolkowe3.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="col-4">
          <div class="grid">
            <figure class="pointer">
              <img src="/assets/images/zespolyrolkowe4.jpg" alt="TECHNIGOR leg sets 2-15/16 in IADC 731Y"
                class="img-fluid" @click="openModalImg4 = !openModalImg4" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg4" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/zespolyrolkowe4.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "ProductsTab5",
  data() {
    return {
      openModalImg1: false,
      openModalImg2: false,
      openModalImg3: false,
      openModalImg4: false
    };
  }
};
</script>

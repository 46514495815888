<template>
  <v-app>
    <div id="app">
      <div class="nav-menu sticky-top">
        <div class="container-fluid fixed">
          <div class="row">
            <div class="col-md-12">
              <nav class="navbar navbar-expand-lg navbar-light py-0 pb-4">
                <a class="navbar-brand" href="/index"><img src="/assets/images/logo.png" alt="logo" /></a>
                <!-- Collapse button -->
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                  aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div id="navbarCollapse" class="collapse navbar-collapse justify-content-end">
                  <ul class="navbar-nav js-scroll-trigger">
                    <li class="nav-item dropdown">
                      <a class="nav-link js-scroll-trigger" :class="{
                        selectedNavItem:
                          (path == 'products')
                          | (path == 'drilling-practices')
                          | (path == 'catalogs')
                          | (path == 'nomenclature')
                      }" to="products" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{
    $t("PRODUCTS")
}}<span class="ti-angle-down"></span></a>
                      <div class="dropdown-menu">
                        <router-link class="dropdown-item" to="/products"
                          :class="{ selectedNavItem: path == 'products' }">{{ $t("PRODUCTS ") }}</router-link>
                        <router-link class="dropdown-item" to="/drilling-practices" :class="{
                          selectedNavItem: path == 'drilling-practices'
                        }">{{ $t("DRILLING_PRACTICES") }}
                        </router-link>
                        <router-link class="dropdown-item" to="/catalogs"
                          :class="{ selectedNavItem: path == 'catalogs' }">{{ $t("CATALOGS") }}</router-link>
                        <router-link class="dropdown-item" to="/nomenclature"
                          :class="{ selectedNavItem: path == 'nomenclature' }">{{ $t("NOMENCLATURE") }}</router-link>
                      </div>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link js-scroll-trigger" to="/theory" :class="{ selectedNavItem: path == 'theory' }">{{
                          $t("IADC_CODE")
                      }}</router-link>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link js-scroll-trigger" :class="{
                        selectedNavItem:
                          (path == 'about-us')
                          | (path == 'gallery')
                          | (path == 'certificates')
                      }" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ $t("COMPANY ")
}}<span class="ti-angle-down"></span></a>
                      <div class="dropdown-menu">
                        <router-link class="dropdown-item" to="/about-us"
                          :class="{ selectedNavItem: path == 'about-us' }">{{ $t("ABOUT_US") }}</router-link>
                        <router-link class="dropdown-item" to="/gallery"
                          :class="{ selectedNavItem: path == 'gallery' }">{{ $t("GALLERY") }}</router-link>
                        <router-link class="dropdown-item" to="/certificates"
                          :class="{ selectedNavItem: path == 'certificates' }">{{ $t("CERTIFICATES") }}</router-link>
                      </div>
                    </li>
                    <li class="nav-item">
                      <router-link class="nav-link js-scroll-trigger" :class="{ selectedNavItem: path == 'contact' }" to="/contact">{{
                          $t("CONTACT")
                      }}</router-link>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link js-scroll-trigger" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{
                          lang
                      }} <i class="ti-world" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-menu">
                        <a href="#" class="dropdown-item" @click="lang = 'pl'">PL</a>
                        <!--<a href="#" class="dropdown-item" @click="lang = 'es'">ES</a> -->
                        <a href="#" class="dropdown-item" @click="lang = 'en'">EN</a>
                      </div>
                    </li>
                    <li class="nav-item">
                      <!--<a class="navbar-brand" href="/certificates"><img src="/assets/images/tuv.png" alt="tÃ¼v certificate"></a> -->
                      <!--<a class="navbar-brand" href="/certificates"><img src="/assets/images/tuv.png"-->
                         <!--alt="tÃ¼v certificate" /></a> -->
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <router-view />

      <footer class="main-block gray">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-4 responsive-wrap">
              <div class="location">
                <i class="fa fa-map-marker" aria-hidden="true">

                </i>
                <p>
                  {{ $t("technigor") }}<br />
                  {{ $t("street") }}<br />
                  38-300 Gorlice<br />
                  {{ $t("poland") }}
                </p>
              </div>
            </div>
            <div class="col-md-4 responsive-wrap">
              <div class="footer-logo_wrap">
                <img src="/assets/images/footer-logo.png" alt="#" class="img-fluid" />
              </div>
            </div>
            <div class="col-md-4 responsive-wrap">
              <div class="social-icons">
                <i class="fa fa-envelope-o" aria-hidden="true"></i>
                sekretariat@technigor.pl<br />
                <li>
                  <i class="fa fa-phone" aria-hidden="true"></i> +48 882 908 099
                </li>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <div class="copyright">
                <p>{{ $t("copyright") }}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </v-app>
</template>


<script>
export default {
  computed: {
    lang: {
      get() {
        return this.$i18n.locale;
      },
      set(value) {
        localStorage.setItem("lang", value);
        this.$i18n.locale = value;
      }
    },
    path() {
      let path = this.$route.path.slice(1);
      let clean_path =
        path.indexOf("/") != -1 ? path.slice(path.indexOf("/" + 1)) : path;

      console.log("Saving: " + clean_path);

      return clean_path;
    }
  }
};


</script>

<style>
.dropdown-item {
  color: #242424 !important;
}

.dropdown-item:active {
  color: #6a8e4d !important;
}

.dropdown-item:hover {
  color: #6a8e4d !important;
}

.selectedNavItem {
  color: #6a8e4d !important;
}

.nav-link:hover {
  color: #6a8e4d !important;
}
</style>

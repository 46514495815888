<template>
  <div>
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("gallery") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--// SUBPAGE HEADER BG -->
    <!--============================= DETAILED ITEM  =============================-->
    <section class="main-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery1.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg1 = !openModalImg1"
                />
              </figure>
            </div>
          </div>
          <v-dialog
            v-model="openModalImg1"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery1.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery2.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg2 = !openModalImg2"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg2"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery2.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery3.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg3 = !openModalImg3"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg3"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery3.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery4.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg4 = !openModalImg4"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg4"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery4.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery5.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg5 = !openModalImg5"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg5"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery5.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery6.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg6 = !openModalImg6"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg6"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery6.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery7.jpg"
                  alt="#"
                  class="img-fluid"
                  @click="openModalImg7 = !openModalImg7"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg7"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery7.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery8.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg8 = !openModalImg8"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg8"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery8.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/galleryA.jpg"
                  alt="#"
                  class="img-fluid"
                  @click="openModalImgA = !openModalImgA"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImgA"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/galleryA.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/galleryB.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImgB = !openModalImgB"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImgB"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/galleryB.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/galleryC.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImgC = !openModalImgC"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImgC"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/galleryC.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/galleryD.jpg"
                  alt="TECHNIGOR - proces produkcji świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImgD = !openModalImgD"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImgD"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/galleryD.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery9.jpg"
                  alt="TECHNIGOR świder trójrolkowy 2-5/8” IADC 211"
                  class="img-fluid"
                  @click="openModalImg9 = !openModalImg9"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg9"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery9.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery10.jpg"
                  alt="TECHNIGOR świder trójrolkowy 2-5/8” IADC 211"
                  class="img-fluid"
                  @click="openModalImg10 = !openModalImg10"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg10"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery10.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery11.jpg"
                  alt="TECHNIGOR świder trójrolkowy 3-1/2” IADC 631Y"
                  class="img-fluid"
                  @click="openModalImg11 = !openModalImg11"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg11"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery11.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery12.jpg"
                  alt="TECHNIGOR świder trójrolkowy 4-3/4” IADC 531Y"
                  class="img-fluid"
                  @click="openModalImg12 = !openModalImg12"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg12"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery12.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery13.jpg"
                  alt="TECHNIGOR świder trójrolkowy 4-3/4” IADC 531Y"
                  class="img-fluid"
                  @click="openModalImg13 = !openModalImg13"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg13"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery13.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery14.jpg"
                  alt="TECHNIGOR świder trójrolkowy 4-3/4” IADC 531Y"
                  class="img-fluid"
                  @click="openModalImg14 = !openModalImg14"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg14"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery14.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/galleryE.jpg"
                  alt=""
                  class="img-fluid"
                  @click="openModalImgE = !openModalImgE"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImgE"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/galleryE.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/galleryF.jpg"
                  alt=""
                  class="img-fluid"
                  @click="openModalImgF = !openModalImgF"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImgF"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/galleryF.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery15.jpg"
                  alt="TECHNIGOR świder trójrolkowy 8-1/2” IADC 633Y"
                  class="img-fluid"
                  @click="openModalImg15 = !openModalImg15"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg15"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery15.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery16.jpg"
                  alt="TECHNIGOR świder trójrolkowy 8-1/2” IADC 633Y"
                  class="img-fluid"
                  @click="openModalImg16 = !openModalImg16"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg16"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery16.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery17.jpg"
                  alt="TECHNIGOR świder trójrolkowy 8-1/2” IADC 633Y"
                  class="img-fluid"
                  @click="openModalImg17 = !openModalImg17"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg17"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery17.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery18.jpg"
                  alt="TECHNIGOR świdry trójrolkowe z łozyskiem nieuszczelnionym"
                  class="img-fluid"
                  @click="openModalImg18 = !openModalImg18"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg18"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery18.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery19.jpg"
                  alt="TECHNIGOR świdry trójrolkowe z łozyskiem nieuszczelnionym"
                  class="img-fluid"
                  @click="openModalImg19 = !openModalImg19"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg19"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery19.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery20.jpg"
                  alt="TECHNIGOR świdry trójrolkowe z łozyskiem nieuszczelnionym"
                  class="img-fluid"
                  @click="openModalImg20 = !openModalImg20"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg20"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery20.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery21.jpg"
                  alt="TECHNIGOR hala prodykcyjna świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg21 = !openModalImg21"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg21"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery21.jpg"> </v-img>
            </v-card>
          </v-dialog>
          <div class="col-md-6">
            <div class="grid">
              <figure class="effect-chico">
                <img
                  src="/assets/images/gallery22.jpg"
                  alt="TECHNIGOR hala prodykcyjna świdrów trójrolkowych"
                  class="img-fluid"
                  @click="openModalImg22 = !openModalImg22"
                />
              </figure>
            </div>
          </div>
          <v-dialog
          v-model="openModalImg22"
            class="modal"
            transition="dialog-bottom-transition"
            width="63%"
          >
            <v-card>
              <v-img src="/assets/images/gallery22.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      openModalImg1: false,
      openModalImg2: false,
      openModalImg3: false,
      openModalImg4: false,
      openModalImg5: false,
      openModalImg6: false,
      openModalImg7: false,
      openModalImg8: false,
      openModalImg9: false,
      openModalImg10: false,
      openModalImg11: false,
      openModalImg12: false,
      openModalImg13: false,
      openModalImg14: false,
      openModalImg15: false,
      openModalImg16: false,
      openModalImg17: false,
      openModalImg18: false,
      openModalImg19: false,
      openModalImg20: false,
      openModalImg21: false,
      openModalImg22: false,
      openModalImgA: false,
      openModalImgB: false,
      openModalImgC: false,
      openModalImgD: false,
      openModalImgE: false,
      openModalImgF: false
    };
  }
};
</script>

<style>
.modal {
  position: absolute;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/Index";
import About from "../components/About";
import Catalogs from "../components/Catalogs";
import Certificates from "../components/Certificates";
import Contact from "../components/Contact";
import DrillingPractices from "../components/DrillingPractices";
import Gallery from "../components/Gallery";
import Nomenclature from "../components/Nomenclature";
import Products from "../components/Products";
import Theory from "../components/Theory";
import Index from "../components/Index";
import ProductsCategory from "../components/ProductsCategory";
/*import ProductsTab1 from "../components/ProductsTabs/ProductsTab1.vue";
import ProductsTab2 from "../components/ProductsTabs/ProductsTab2.vue";
import ProductsTab3 from "../components/ProductsTabs/ProductsTab3.vue";
import ProductsTab4 from "../components/ProductsTabs/ProductsTab4.vue";
import ProductsTab5 from "../components/ProductsTabs/ProductsTab5.vue";*/

Vue.use(VueRouter);

const routes = [
  /*{
    path: '/products-tab1',
    name: 'ProductsTab1',
    component: ProductsTab1
  },
  {
    path: '/products-tab2',
    name: 'ProductsTab2',
    component: ProductsTab2
  },
  {
    path: '/products-tab3',
    name: 'ProductsTab3',
    component: ProductsTab3
  },
  {
    path: '/products-tab4',
    name: 'ProductsTab4',
    component: ProductsTab4
  },
  {
    path: '/products-tab5',
    name: 'ProductsTab5',
    component: ProductsTab5
  },*/
  {
    path: "/index",
    name: "Index",
    component: Index
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about-us",
    name: "About",
    component: About
  },
  {
    path: "/catalogs",
    name: "Catalogs",
    component: Catalogs
  },
  {
    path: "/certificates",
    name: "Certificates",
    component: Certificates
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/drilling-practices",
    name: "DrillingPractices",
    component: DrillingPractices
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery
  },
  {
    path: "/nomenclature",
    name: "Nomenclature",
    component: Nomenclature
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/theory",
    name: "Theory",
    component: Theory
  },
  {
    path: "/products-category/:id",
    name: "ProductsCategory",
    component: ProductsCategory
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;

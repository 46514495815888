import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import PL from "./lang/pl.json";
import EN from "./lang/en.json";
import ES from "./lang/es.json";

Vue.use(VueI18n);

const messages = {
  pl: PL,
  en: EN,
  es: ES
};
let currentLang = "PL";
if (typeof localStorage.getItem("lang") !== undefined) {
  currentLang = localStorage.getItem("lang");
}

const i18n = new VueI18n({
  locale: currentLang,
  messages
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  vuetify,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");

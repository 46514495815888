<template>
  <div>
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("nomenclature") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--// SUBPAGE HEADER BG -->
    <!--============================= BLOG DETAIL =============================-->
    <section class="main-block container ">
      <div class="main-block">
        <div class="container">
          <div class="pb-16">
            <h2>
              {{ $t("identificator1") }}
            </h2>
            
          </div>
          <div class="row pb-8">
            <div class="col-4">
              <h1><span style="color: #6d8c4f">S</span> 73 GB</h1>
            </div>
            <div class="col-8">
              <h4>{{ $t("identificator2") }}</h4>
              <p>
                <ul>
                  <li class="">
                    {{ $t("identificator3a") }}
                    <a href="/products-category/1"
                      ><i class="ti-info-alt"></i
                    ></a>
                  </li>
                
                  <!--<li>
                    {{ $t("identificator3b") }}
                    <a href="/products-category/2">
                      <i class="ti-info-alt"></i
                    ></a>
                  </li>-->
                  <li>
                    {{ $t("identificator3c") }}
                    <a href="/products-category/3">
                      <i class="ti-info-alt"></i
                    ></a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div class="row pb-8">
            <div class="col-4">
              <h1>S <span style="color: #6d8c4f">73</span> GB</h1>
            </div>
            <div class="col-8">
              <h4>{{ $t("identificator4") }}</h4>
              <p>
                {{ $t("identificator5") }}
                <a href="/theory"> <i class="ti-info-alt"></i></a>
              </p>
            </div>
          </div>
          <div class="row pb-8">
            <div class="col">
              <h1>S 73 <span style="color: #6d8c4f">G</span>B</h1>
            </div>
            <div class="col">
              <h4>
                {{ $t("identificator6") }}
              </h4>
              <p>
                {{ $t("identificator7") }}
              </p>
            </div>
            <div class="col">
              <img
                src="/assets/images/nomenclature1.jpg"
                alt="#"
                class="img-fluid"
                @click="openModalImg17 = !openModalImg17"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <h1>S 73 G<span style="color: #6d8c4f">B</span></h1>
            </div>
            <div class="col">
              <h4>
                {{ $t("identificator8") }}
              </h4>
              <p>
                {{ $t("identificator9") }}
              </p>
            </div>
            <div class="col">
              <img
                src="/assets/images/nomenclature2.jpg"
                alt="#"
                class="img-fluid"
                @click="openModalImg17 = !openModalImg17"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Nomenclature"
};
</script>

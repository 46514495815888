<template>
  <div>
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("our_products") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--// SUBPAGE HEADER BG -->
    <!--============================= BLOG 2 =============================-->
    <!--============================= DETAILED ITEM  =============================-->

    <section class="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="grid">
              <div class="">
                <figure class="effect-chico">
                  <router-link to="products-category/1">
                    <img
                      src="/assets/images/nieuszczelniony.jpg"
                      alt="#"
                      class="img-fluid"
                    />
                    <figcaption>
                      <div class="card-img-overlay">
                        <div class="my-auto mx-auto">
                          <h1 class="lead" style="font-weight:400;">{{ $t("type1") }}</h1>
                          <p>{{ $t("items1") }}</p>
                        </div>
                      </div>
                    </figcaption>
                  </router-link>
                </figure>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="grid">
              <div class="">
                <figure class="effect-chico">
                  <router-link to="products-category/2">
                    <img
                      src="/assets/images/uszczelniony.jpg"
                      alt="#"
                      class="img-fluid"
                    />
                    <figcaption>
                      <div class="card-img-overlay">
                        <div class="my-auto mx-auto">
                          <h1 class="lead" style="font-weight:400;">{{ $t("type2") }}</h1>
                        </div>
                      </div>
                    </figcaption>
                  </router-link>
                </figure>
              </div>
            </div>
          </div> -->
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="grid">
              <div class="">
                <figure class="effect-chico">
                  <router-link to="products-category/3">
                    <img
                      src="/assets/images/lewyobieg.jpg"
                      alt="#"
                      class="img-fluid"
                    />

                    <figcaption>
                      <div class="card-img-overlay">
                        <div class="my-auto mx-auto">
                          <h1 class="lead" style="font-weight:400;">{{ $t("type3") }}</h1>
                        </div>
                      </div>
                    </figcaption>
                  </router-link>
                </figure>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grid">
              <div class="">
                <figure class="effect-chico">
                  <router-link to="products-category/4">
                    <img
                      src="/assets/images/geoterm.jpg"
                      alt="#"
                      class="img-fluid"
                    />
                    <figcaption>
                      <div class="card-img-overlay">
                        <div class="my-auto mx-auto">
                          <h1 class="lead" style="font-weight:400;">{{ $t("type4") }}</h1>
                        </div>
                      </div>
                    </figcaption>
                  </router-link>
                </figure>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grid">
              <div class="">
                <figure class="effect-chico">
                  <router-link to="products-category/5">
                    <img
                      src="/assets/images/zespolyrolkowe.jpg"
                      alt="#"
                      class="img-fluid"
                    />
                    <figcaption>
                      <div class="card-img-overlay">
                        <div class="my-auto mx-auto">
                          <h1 class="lead" style="font-weight:400;">{{ $t("type5") }}</h1>
                        </div>
                      </div>
                    </figcaption>
                  </router-link>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import ProductsTable from "./ProductsTable";
import ProductsTab1 from "./ProductsTabs/ProductsTab1";
import ProductsTab2 from "./ProductsTabs/ProductsTab2";
import ProductsTab3 from "./ProductsTabs/ProductsTab3";
import ProductsTab4 from "./ProductsTabs/ProductsTab4";
import ProductsTab5 from "./ProductsTabs/ProductsTab5";

export default {
  name: "Products",
  components: {
    ProductsTable,
    ProductsTab1,
    ProductsTab2,
    ProductsTab3,
    ProductsTab4,
    ProductsTab5
  },
  data() {
    return {
      productTab: "tab1"
    };
  }
};
</script>



var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"subpage-bg"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"titile-block title-block_subpage"},[_c('h2',[_vm._v(_vm._s(_vm.$t("nomenclature")))])])])])])]),_c('section',{staticClass:"main-block container"},[_c('div',{staticClass:"main-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pb-16"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("identificator1"))+" ")])]),_c('div',{staticClass:"row pb-8"},[_vm._m(0),_c('div',{staticClass:"col-8"},[_c('h4',[_vm._v(_vm._s(_vm.$t("identificator2")))]),_c('p',[_c('ul',[_c('li',{},[_vm._v(" "+_vm._s(_vm.$t("identificator3a"))+" "),_vm._m(1)]),_c('li',[_vm._v(" "+_vm._s(_vm.$t("identificator3c"))+" "),_vm._m(2)])])])])]),_c('div',{staticClass:"row pb-8"},[_vm._m(3),_c('div',{staticClass:"col-8"},[_c('h4',[_vm._v(_vm._s(_vm.$t("identificator4")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("identificator5"))+" "),_vm._m(4)])])]),_c('div',{staticClass:"row pb-8"},[_vm._m(5),_c('div',{staticClass:"col"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("identificator6"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("identificator7"))+" ")])]),_c('div',{staticClass:"col"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/assets/images/nomenclature1.jpg","alt":"#"},on:{"click":function($event){_vm.openModalImg17 = !_vm.openModalImg17}}})])]),_c('div',{staticClass:"row"},[_vm._m(6),_c('div',{staticClass:"col"},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("identificator8"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("identificator9"))+" ")])]),_c('div',{staticClass:"col"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/assets/images/nomenclature2.jpg","alt":"#"},on:{"click":function($event){_vm.openModalImg17 = !_vm.openModalImg17}}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('h1',[_c('span',{staticStyle:{"color":"#6d8c4f"}},[_vm._v("S")]),_vm._v(" 73 GB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/products-category/1"}},[_c('i',{staticClass:"ti-info-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/products-category/3"}},[_c('i',{staticClass:"ti-info-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('h1',[_vm._v("S "),_c('span',{staticStyle:{"color":"#6d8c4f"}},[_vm._v("73")]),_vm._v(" GB")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/theory"}},[_c('i',{staticClass:"ti-info-alt"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h1',[_vm._v("S 73 "),_c('span',{staticStyle:{"color":"#6d8c4f"}},[_vm._v("G")]),_vm._v("B")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('h1',[_vm._v("S 73 G"),_c('span',{staticStyle:{"color":"#6d8c4f"}},[_vm._v("B")])])])
}]

export { render, staticRenderFns }
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":"8"}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{attrs:{"cols":"4"}},[_c(VCombobox,{attrs:{"clearable":"","outlined":"","items":_vm.headers,"label":"Filter","item-text":"text","item-value":"value"},model:{value:(_vm.selectedFilterItem),callback:function ($$v) {_vm.selectedFilterItem=$$v},expression:"selectedFilterItem"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":100,"search":_vm.search,"custom-filter":_vm.filterByCategory},scopedSlots:_vm._u([{key:"header.diameter_inch",fn:function({ header }){return [_c('span',{staticClass:"mojaklasa"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.diameter_mm",fn:function({ header }){return [_c('span',{staticClass:"mojaklasa"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.Iadc_code",fn:function({ header }){return [_c('span',{staticClass:"mojaklasa"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.name",fn:function({ header }){return [_c('span',{staticClass:"mojaklasa"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.pin_size",fn:function({ header }){return [_c('span',{staticClass:"mojaklasa"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.flushing",fn:function({ header }){return [_c('span',{staticClass:"mojaklasa"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.diameter_inch",fn:function({ item }){return [_c('span',{staticClass:"item"},[_vm._v(" "+_vm._s(item.diameter_inch)+" ")])]}}])}),_c('table',{staticClass:"table table-hover"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
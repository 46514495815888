<template>
  <div class="">
    <div class="container pb-4 pt-4">
      <div class="" style="margin-top: 24px ">
        <h2 class="pb-2">{{ $t("swidry2-text0") }}</h2>
        <p class="text-justify">
          {{ $t("swidry2-text") }}
        </p>
        <div class="button1">{{ $t("seria-f") }}</div>
      </div>
    </div>
    <div class="container">
      <div class="">
        <div class="">
          <div  class="pb-4">
            <figure class=" pointer">
              <img src="/assets/images/uszczelniony1.jpg" alt="TECHNIGOR roller cone bit 5-1/2 in IADC 637Y 01"
                class="img-fluid" @click="openModalImg1 = !openModalImg1" />
            </figure>
          </div>

        </div>
      </div>
      <div class="">
        <div class="pt-4 text-justify">
        <h6>{{ $t("swidry2-textA") }}</h6>
        <p class="text-justify">
          {{ $t("swidry2-textB") }}
        </p>
        <hr />
        <h6>{{ $t("swidry2-text2") }}</h6>
        <div class="">
          <div class="">
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{
                  $t("swidry2-text3")
              }}</span>
            </label>
          </div>
          <div>  
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{ $t("swidry2-text4") }}
              </span>
            </label>
          </div>
          <div>  
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{ $t("swidry2-text7") }}
              </span>
            </label>
          </div>
          <div class="">
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{ $t("swidry2-text5") }}
              </span>
            </label>
          </div>
          <div>  
            <label class="custom-checkbox">
              <span class="ti-check-box"></span>
              <span class="custom-control-description">{{
                  $t("swidry2-text6")
              }}</span>
            </label>
          </div>
          <div class="col-md-6"></div>
        </div>
      </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="">
            <figure class="pointer">
              <img src="/assets/images/uszczelniony2.jpg" alt="TECHNIGOR roller cone bit 5-1/2 in IADC 637Y 02"
                class="img-fluid" @click="openModalImg2 = !openModalImg2" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg2" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/uszczelniony2.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="col-4">
          <div class="">
            <figure class="pointer">
              <img src="/assets/images/uszczelniony3.jpg" alt="TECHNIGOR roller cone bit 5-1/2 in IADC 217 03"
                class="img-fluid" @click="openModalImg3 = !openModalImg3" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg3" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/uszczelniony3.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
        <div class="col-4">
          <div class="">
            <figure class="pointer">
              <img src="/assets/images/uszczelniony4.jpg" alt="TECHNIGOR roller cone bit 5-1/2 in IADC 217Y 04"
                class="img-fluid" @click="openModalImg4 = !openModalImg4" />
            </figure>
          </div>
          <v-dialog v-model="openModalImg4" class="modal" transition="dialog-bottom-transition" width="62%">
            <v-card>
              <v-img src="/assets/images/uszczelniony4.jpg"> </v-img>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ProductsTab2",
  data() {
    return {
      openModalImg1: false,
      openModalImg2: false,
      openModalImg3: false,
      openModalImg4: false
    };
  }
};
</script>



<template>
  <div class="table-responsive">
    <v-card-title>
      <v-row>
        <v-col cols="8">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-combobox v-model="selectedFilterItem" clearable outlined :items="headers" label="Filter" item-text="text"
            item-value="value"></v-combobox>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table :headers="headers" :items="items" :items-per-page="100" :search="search"
      :custom-filter="filterByCategory">
      <template v-slot:header.diameter_inch="{ header }">
        <span class="mojaklasa">
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.diameter_mm="{ header }">
        <span class="mojaklasa">
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.Iadc_code="{ header }">
        <span class="mojaklasa">
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.name="{ header }">
        <span class="mojaklasa">
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.pin_size="{ header }">
        <span class="mojaklasa">
          {{ header.text }}
        </span>
      </template>
      <template v-slot:header.flushing="{ header }">
        <span class="mojaklasa">
          {{ header.text }}
        </span>
      </template>
      <template v-slot:item.diameter_inch="{ item }">
        <span class="item">
          {{ item.diameter_inch }}
        </span>
      </template>
    </v-data-table>
    <table class="table table-hover"></table>
  </div>
</template>
<script>
import technigor_products from "./technigor.json";
export default {
  name: "ProductsTable",
  data() {
    return {
      search: "",
      selectedFilterItem: "",
      headers: [
        {
          text: "Diameter [in]",
          value: "diameter_inch"
        },
        {
          text: "Diameter [mm]",
          value: "diameter_mm"
        },
        {
          text: "IADC Code",
          value: "Iadc_code"
        },
        {
          text: "Technigor name",
          value: "name"
        },
        {
          text: "Pin Size",
          value: "pin_size"
        },
        {
          text: "Flushing",
          value: "flushing"
        }
      ],
      items: technigor_products
    };
  },
  methods: {
    filterByCategory(value, search, item) {
      if (value !== null && search != null && typeof value === "string") {
        let selectedKey = "";
        if (this.selectedFilterItem) {
          selectedKey = this.selectedFilterItem.value;
        }
        if (!selectedKey) {
          return value.toLowerCase().indexOf(search.toLowerCase()) > -1;
        } else {
          return (
            item[selectedKey].toLowerCase().indexOf(search.toLowerCase()) > -1
          );
        }
      }
      return false;
    }
  }
};
</script>

<style>
.mojaklasa {
  font-weight: bold;
  font-size: 18px;
}
</style>

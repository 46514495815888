<template>
  <div>
    <!--============================= SUBPAGE HEADER BG =============================-->
    <section class="subpage-bg">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="titile-block title-block_subpage">
              <h2>{{ $t("IADC_Code") }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--// SUBPAGE HEADER BG -->
    <!--============================= PAYMENT METHORD =============================-->
    <section class="main-block">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-8">
                <form method="post">
                  <!-- General Information -->

                  <div class="payment-wrap">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="payment-title text-justify">
                          <h2>{{ $t("IADC_Code") }}</h2>
                          <hr />
                          <p>
                            {{ $t("IADC_Code2") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <!--// General Information -->
              </div>

              <div class="col-md-4">
                <div class="booking-summary_block">
                  <img
                    src="/assets/images/featured-img1.jpg"
                    class="img-fluid"
                    alt="#"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--//END PAYMENT METHORD -->
    <!-- item list  -->
    <section class="booking-details_wrap gray">
      <div class="container">
        <div class="titile-block">
          <h2 class="padding1">
            {{ $t("IADC_Code") }} - {{ $t("IADC_Code3") }}
            {{ $t("IADC_Code4") }}
          </h2>
          <img
            src="/assets/images/IADC1.png"
            alt="IADC -1st digit"
            width="250"
            height="110"
          />
        </div>
        <div class="table-responsive-1">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="center">{{ $t("IADC_Code5") }}</th>
                <th class="pt-0">{{ $t("IADC_Code6") }}</th>
                <th class="pt-0">{{ $t("IADC_Code7") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td
                  rowspan="3"
                  style="vertical-align: middle; text-align: center"
                  bgcolor="F2F2F2"
                >
                  {{ $t("IADC_Code8") }}
                </td>
                <td>{{ $t("IADC_Code9") }}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>{{ $t("IADC_Code10") }}</td>
              </tr>
              <tr>
                <td>3</td>
                <td>{{ $t("IADC_Code11") }}</td>
              </tr>
              <tr>
                <td>4</td>
                <td
                  rowspan="5"
                  style="vertical-align: middle; text-align: center"
                  bgcolor="E2E2E2"
                >
                  {{ $t("IADC_Code12") }}
                </td>
                <td>{{ $t("IADC_Code13") }}</td>
              </tr>
              <tr>
                <td>5</td>
                <td>{{ $t("IADC_Code14") }}</td>
              </tr>
              <tr>
                <td>6</td>
                <td>{{ $t("IADC_Code15") }}</td>
              </tr>
              <tr>
                <td>7</td>
                <td>{{ $t("IADC_Code16") }}</td>
              </tr>
              <tr>
                <td>8</td>
                <td>{{ $t("IADC_Code17") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section class="booking-details_wrap gray">
      <div class="container">
        <div class="titile-block">
          <h2 class="padding1">
            {{ $t("IADC_Code") }} - {{ $t("IADC_Code18") }}
            {{ $t("IADC_Code19") }}
          </h2>
          <img
            src="/assets/images/IADC2.png"
            alt="IADC -1st digit"
            width="250"
            height="110"
          />
        </div>
        <div class="table-responsive-1">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="center">{{ $t("IADC_Code20") }}</th>
                <th class="pt-0">
                  {{ $t("IADC_Code21") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>{{ $t("IADC_Code22") }}</td>
              </tr>
              <tr>
                <td>2</td>
                <td>↓</td>
              </tr>
              <tr>
                <td>3</td>
                <td>↓</td>
              </tr>
              <tr>
                <td>4</td>
                <td>{{ $t("IADC_Code23") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section class="booking-details_wrap gray">
      <div class="container">
        <div class="titile-block">
          <h2 class="padding1">
            {{ $t("IADC_Code") }} - {{ $t("IADC_Code24") }}
            {{ $t("IADC_Code25") }}
          </h2>
          <img
            src="/assets/images/IADC3.png"
            alt="IADC -1st digit"
            width="250"
            height="110"
          />
        </div>
        <div class="table-responsive-1">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="center">{{ $t("IADC_Code26") }}</th>
                <th class="pt-0">{{ $t("IADC_Code27") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code28_standard") }}
                </td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code29_lack") }} {{ $t("IADC_Code30_gage") }}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code28_standard") }}; {{ $t("IADC_Code31") }}
                </td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code28_standard") }}
                </td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code32_TCI") }} {{ $t("IADC_Code30_gage") }}
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code33_roller") }}
                </td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code29_lack") }} {{ $t("IADC_Code30_gage") }}
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code33_roller") }}
                </td>

                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code32_TCI") }} {{ $t("IADC_Code30_gage") }}
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <span class="ti-check-box"></span
                  >{{ $t("IADC_Code34_journal") }}
                </td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code29_lack") }} {{ $t("IADC_Code30_gage") }}
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code34_journal") }}
                </td>
                <td>
                  <span class="ti-check-box"></span>
                  {{ $t("IADC_Code32_TCI") }} {{ $t("IADC_Code30_gage") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Theory"
};
</script>
